module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div id="' +
((__t = ( instanceId )) == null ? '' : __t) +
'" class="wistia-modal__embed wistia_embed wistia_async_' +
((__t = ( wistiaId )) == null ? '' : __t) +
'">&nbsp;</div>\n';

}
return __p
}