module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="wistia-modal">\n  <div class="wistia-modal__spinner"></div>\n  <div class="wistia-modal__embed-container">' +
((__t = ( embed )) == null ? '' : __t) +
'</div>\n</div>\n';

}
return __p
}