module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="visuallyhidden" aria-hidden="true">' +
((__t = ( embed )) == null ? '' : __t) +
'</div>\n';

}
return __p
}