import {Modal, uniqueId} from '@shopify/marketing-assets';

import containerTemplate from './container.ejs';
import embedTemplate from './embed.ejs';
import hiddenContainerTemplate from './hidden-container.ejs';

const MAIN_SCRIPT_URL = 'https://fast.wistia.com/assets/external/E-v1.js';
const JSON_DATA_URL = (id) => `https://fast.wistia.com/embed/medias/${id}.jsonp`;
window._wq = window._wq || [];

class WistiaModal {
  constructor(...args) {
    const {
      $trigger,
      wistiaId,
      wistiaOptions,
    } = this._resolveArgs(...args);
    Object.assign(this, {$trigger, wistiaId, wistiaOptions});

    this._instanceId = uniqueId();
    this._videoIsReadyToPlay = false;
    this._loadWistiaHasRun = false;

    this._parser = new DOMParser();
    this._$container = this._generateContainer();

    [
      '_modalTemplate',
      '_loadWistia',
      '_onVideoEmbedded',
      '_onVideoPlayed',
      '_onModalOpened',
    ].forEach((method) => {
      this[method] = this[method].bind(this);
    });

    window._wq.push(this._wistiaConfig);

    this._modal = this._initiateModal();
  }

  get _wistiaLoaded() {
    return this._loadWistiaHasRun || window.Wistia !== undefined;
  }

  get _wistiaConfig() {
    return {
      id: this._instanceId,
      options: {
        autoPlay: true,
        silentAutoPlay: false,
        ...this.wistiaOptions,
      },
      onEmbedded: this._onVideoEmbedded,
    };
  }

  _resolveArgs($trigger, optionalWistiaId, wistiaOptions = {}) {
    const resolved = {};

    if (!($trigger instanceof HTMLElement)) {
      throw new Error('WistiaModal wasn\'t passed an HTMLElement');
    }

    resolved.$trigger = $trigger;
    resolved.wistiaOptions = wistiaOptions;

    if (typeof optionalWistiaId === 'object') {
      resolved.wistiaOptions = optionalWistiaId;
    } else if (typeof optionalWistiaId === 'string') {
      resolved.wistiaId = optionalWistiaId;
    }

    if (!resolved.wistiaId && resolved.$trigger.dataset.wistiaId) {
      resolved.wistiaId = resolved.$trigger.dataset.wistiaId;
    }

    if (!resolved.wistiaId) {
      throw new Error('WistiaModal couldn\'t resolve to a Wistia ID');
    }

    return resolved;
  }

  _initiateModal() {
    const modal = new Modal(this.$trigger, this._modalTemplate, {
      modalStyleModifierClasses: {
        container: 'modal-container--wistia modal-container--lowlight',
        closeIcon: 'icon-modules-close-white',
      },
    });

    modal.on('opened', this._onModalOpened);

    return modal;
  }

  _modalTemplate() {
    return this._$container;
  }

  _generateContainer() {
    const embed = embedTemplate({
      instanceId: this._instanceId,
      wistiaId: this.wistiaId,
    });
    const html = containerTemplate({embed});
    return this._htmlStringToElement(html);
  }

  _generateHiddenContainer() {
    const embed = embedTemplate({
      instanceId: `hidden_${this._instanceId}`,
      wistiaId: this.wistiaId,
    });
    const html = hiddenContainerTemplate({embed});
    return this._htmlStringToElement(html);
  }

  _htmlStringToElement(html) {
    const document = this._parser.parseFromString(html, 'text/html');
    return document.body.firstChild;
  }

  _onModalOpened() {
    if (this.video) { this.video.play(); }

    if (this._wistiaLoaded) { return; }
    this._loadWistia();
  }

  _onVideoPlayed() {
    if (this._videoHasPlayed) { return; }
    this._videoHasPlayed = true;
    this._$container.classList.add('has-played');
  }

  _onVideoEmbedded(video) {
    this.video = video;
    this.video.bind('play', this._onVideoPlayed);
  }

  _loadWistia() {
    this._loadWistiaHasRun = true;
    this._loadScript(JSON_DATA_URL(this.wistiaId));
    this._loadScript(MAIN_SCRIPT_URL);
  }

  _loadScript(url) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    document.body.appendChild(script);
    script.src = url;
  }
}

export default WistiaModal;
