import {analytics, Modal, cookieHelper} from '@shopify/marketing-assets';

ShopifyMarketing.InterstitialModal = (function() {
  const Cookie = cookieHelper;

  function InterstitialModal(template, options) {
    this.templateElement = template;
    this.options = options || {};

    // Empty trigger
    this.trigger = [];

    this.body = document.body;
    this.activeClass = 'js-interstitial-modal-active';

    const closeTrigger = document.querySelector('.js-close-modal');
    if (closeTrigger) {
      document.body.addEventListener('click', (evt) => {
        if (evt.target.classList.contains('js-close-modal')) {
          this.close({
            fromCta: true,
          });
        }
      });
    }

    Modal.call(this, this.trigger, this.template.bind(this), {
      clickingOverlayClosesModal: false,
      ...this.options,
    });
  }

  InterstitialModal.prototype = Object.create(Modal.prototype);

  InterstitialModal.prototype.open = function(...args) {
    if (this.options.showOncePerSession && this.clientHasViewedInterstitial()) {
      return;
    }

    this.body.classList.add(this.activeClass);
    analytics.track('Free Trial', 'Interstitial', 'open');
    Modal.prototype.open.apply(this, args);
  };

  InterstitialModal.prototype.close = function(options) {
    const closeOptions = options || {};

    Modal.prototype.close.call(this, () => {
      this.body.classList.remove(this.activeClass);
      analytics.track('Free Trial', 'Interstitial', 'close');
    });

    if (this.options.showOncePerSession && closeOptions.fromCta) {
      this.setClientHasViewedCookie();
    }
  };

  InterstitialModal.prototype.template = function() {
    const node = this.templateElement.cloneNode(true);
    node.removeAttribute('style');
    node.removeAttribute('id');
    return node.outerHTML;
  };

  InterstitialModal.prototype.clientHasViewedInterstitial = function() {
    return Boolean(Cookie.get(this.options.cookie));
  };

  InterstitialModal.prototype.setClientHasViewedCookie = function() {
    Cookie.set(this.options.cookie, 1);
  };

  return InterstitialModal;
})();
