import '../../../modules/interstitial-modal';
import '../../../application';
import Waypoint from 'waypoints';

import WistiaModal from '../../../modules/wistia-modal';

(function() {
  const $interstitialTemplate = document.getElementById('InterstitialModalTemplate');
  if ($interstitialTemplate) {
    new ShopifyMarketing.InterstitialModal($interstitialTemplate).open();
  }

  const $el = document.querySelector('.js-wistia-modal');

  if ($el) {
    new WistiaModal($el);
  }

  // 3F Signup Form Experiment
  document.addEventListener('DOMContentLoaded', () => {
    // This function is used to log the experiment group assignment when the modal is opened for the 3f_storename_autogen experiment
    const logExperimentAssignment = () => {
      const isEnLocale = (window?.analytics?.trekkie?.defaultAttributes?.locale === "en");
      const isComSite = window.location.host.match('com$');
      const isFreeTrialRoot = window.location.pathname.match('free-trial/*$');
      if (isEnLocale && isComSite && isFreeTrialRoot) {
        window.analytics.track('monorail://experiments_dotcom_assign/3.0', {
          /* eslint-disable babel/camelcase */
          uniqToken: window.analytics.trekkie.defaultAttributes.uniqToken,
          visitToken: window.analytics.trekkie.defaultAttributes.visitToken,
          microSessionId: window.analytics.trekkie.defaultAttributes.microSessionId,
          page_url: window.location.href,
          source_app: 'brochure2',
          experiment_handle: '3f_storename_autogen',
          variation_name: 'control',
          experiment_start_at: '2021-07-08T00:00:00-04:00',
          /* eslint-enable babel/camelcase */
        });
      }
    };
    if (document.querySelector(".js-signup-inline")) {
      document.querySelector(".js-signup-inline").addEventListener('submit', logExperimentAssignment);
    }
  });
})();

// Mobile Sticky Banner Experiment
(function() {
  const stickyBannerElem = document.getElementById("sticky-banner");
  const buttonElem = document.querySelector(".free-trial__signup-wrapper .free-trial__button");

  if (!stickyBannerElem || !buttonElem) {
    return;
  }

  new Waypoint({
    element: buttonElem,
    offset: 8,
    handler: (dir) => {
      stickyBannerElem.style.display = dir === "down" ? "block" : "none";
    },
  });
})();
